<template>
  <div class="home" style="overflow:hidden">
    <div v-if="isLoading">
      <LoadingHome></LoadingHome>
    </div>
    <div v-else>
      <!-- 大電視-桌機 -->
      <div class="big-banner d-none d-md-block">
        <swiper
        :pagination="true"
        :loop="true"
        :autoplay="{
        delay: 5000,
        disableOnInteraction: false
        }"
        :spaceBetween="0"
        :initialSlide="0"
        class="mySwiper h-100">
          <swiper-slide v-for="(item,index) in banner" :key="index+'A'">
            <a :href="item.targetUrl">
              <img class="w-100" :src="item.image" :alt="item.name">
            </a>
          </swiper-slide>
        </swiper>
      </div>
      <!-- 大電視-手機 -->
      <div class="big-banner d-md-none">
        <swiper
        :pagination="true"
        :loop="true"
        :autoplay="{
        delay: 5000,
        disableOnInteraction: false
        }"
        :spaceBetween="0"
        :initialSlide="0"
        class="mySwiper h-100">
          <swiper-slide v-for="(item,index) in banner_m" :key="index+'B'">
            <a :href="item.targetUrl">
              <img class="w-100" :src="item.image" :alt="item.name">
            </a>
          </swiper-slide>
        </swiper>
      </div>
      <div class="container">
        <!-- 首頁影片 -->
        <div class="row justify-content-center mt-4 mt-lg-5" v-if="video">
          <div class="col-12 col-lg-10">
            <!-- YT 嵌入 -->
            <div v-if="video.src==='yt'" class="ratio ratio-16x9">
              <iframe class="w-100" :src="video.url" autoplay="autoplay" frameborder="0"></iframe>
            </div>
            <!--  mp4 播放 -->
            <div v-if="video.src==='aws'" class="ratio ratio-16x9">
              <video id="vid" class="w-100" loop="true" controls muted playsinline>
                <source :src="`${video.url}#t=0.001`" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="d-flex justify-content-center mt-4">
            <h4 class="fs-10 fs-sm-3 fs-md-2 fs-lg-4 mx-auto mt-4 mb-4 mb-lg-5 mt-lg-5 pb-2 text-dark text-center fw-medium fw-lg-light border-bottom border-dark border-2" style="width:fit-content;">優惠活動</h4>
          </div>
          <div class="col-12 col-lg-10">
            <div class="row gy-3 justify-content-center">
              <div class="col-12 col-lg-6" v-for="item in activitybanner1" :key="item.code">
                <a :href="item.targetUrl" class="w-100">
                  <img :src="item.image" alt="" class="w-100">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 justify-content-center mt-4">
            <h4 class="fs-10 fs-sm-3 fs-md-2 fs-lg-4 mx-auto mt-4 mb-4 mb-lg-5 mt-lg-5 pb-2 text-dark text-center fw-medium fw-lg-light border-bottom border-dark border-2" style="width:fit-content;">季節限定</h4>
          </div>
          <div class="col-12 col-lg-10">
            <div class="row gy-3 justify-content-center">
              <div class="col-12 col-lg-6" v-for="item in activitybanner2" :key="item.code">
                <a :href="item.targetUrl" class="w-100">
                  <img :src="item.image" alt="" class="w-100">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 justify-content-center mt-4">
            <h4 class="fs-10 fs-sm-3 fs-md-2 fs-lg-4 mx-auto mt-4 mb-4 mb-lg-5 mt-lg-5 pb-2 text-dark text-center fw-medium fw-lg-light border-bottom border-dark border-2" style="width:fit-content;">最新消息</h4>
          </div>
          <div class="col-12 col-lg-10">
            <div class="row gy-3 justify-content-center">
              <div class="col-12 col-lg-6" v-for="item in activitybanner3" :key="item.code">
                <a :href="item.targetUrl" class="w-100">
                  <img :src="item.image" alt="" class="w-100">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 justify-content-center mt-4">
            <h4 class="fs-10 fs-sm-3 fs-md-2 fs-lg-4 mx-auto mt-4 mb-4 mb-lg-5 mt-lg-5 pb-2 text-dark text-center fw-medium fw-lg-light border-bottom border-dark border-2" style="width:fit-content;">明星商品</h4>
          </div>
          <div class="col-10 d-none d-lg-block">
            <RelativeProductList></RelativeProductList>
          </div>
          <div class="col-12 d-lg-none">
            <div class="row gy-3">
              <div class="col-6" v-for="item in hotProduct" :key="item.Id">
                <div class="card border-0">
                  <router-link :to="`/product/${item.Id}`" class="text-dark">
                    <img
                      :src="item.Image"
                      class="card-img-top"
                      :alt="item.Name"
                    />
                    <div class="card-body px-0 pt-3">
                      <h5 class="card-title title-height fs-10 fs-md-5 fw-medium text-center">
                        {{item.Name}}
                      </h5>
                      <div class="text-center fs-6 fs-md-5">
                        <del class="card-text text-secondary">NT${{$currency.currency(item.MSRP)}}</del>
                        <p class="d-inline text-danger ms-2 wrap">
                          NT$ <span class="fs-4 fs-md-3">{{$currency.currency(item.Price)}}</span>
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-5 justify-content-center">
          <div class="col-10 mb-5 d-none d-lg-block">
            <img src="../assets/img/home_pc.jpg" alt="" class="w-100">
          </div>
          <div class="col-12 mb-5 d-lg-none">
            <img src="../assets/img/home_mb.jpg" alt="" class="w-100">
          </div>
        </div>
      </div>
    </div>
    <!-- 蓋板廣告 -->
    <div ref="adModal" class="modal fade">
      <div class="adModal modal-dialog modal-dialog-centered mx-auto px-3 px-sm-0" style="width:fit-content;">
        <div class="modal-content position-relative">
          <a href="#" class="closeEvent d-block position-absolute end-0 top-0" @click.prevent="closeAdModal">
            <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/frontend/line_delete.png" alt="關閉廣告" data-cy="closeEventIcon" class="closeEventIcon img-fluid">
          </a>
          <a v-if="advertise_m" :href="advertise_m.targetUrl" class="d-lg-none">
            <img :src="advertise_m.image" alt="蓋板廣告" class="img-fluid">
          </a>
          <a v-if="advertise" :href="advertise.targetUrl" class="d-none d-lg-block">
            <img :src="advertise.image" alt="蓋板廣告" class="img-fluid">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RelativeProductList from '../components/RelativeProductList.vue'
import LoadingHome from '../views/Loading/LoadingHome.vue'
import ProductSvc from '@/service/product-service.js'
import BannerSvc from '@/service/banner-service.js'
import getGiftEvent from '@/assets/js/giftevent.js'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import moment from 'moment'
import checkVideoSrc from '../assets/js/checkVideoSrc'

export default {
  components: {
    RelativeProductList,
    LoadingHome
  },
  data () {
    return {
      banner: [],
      banner_m: [],
      video: {},
      activitybanner1: [], // ? 優惠活動
      activitybanner2: [], // ? 季節限定
      activitybanner3: [], // ? 最新消息
      hotProduct: [],
      isLoading: false, // ?進場動畫開關
      advertise: {}, // ?蓋板廣告 PC
      advertise_m: {}, // ?蓋板廣告 MB
      adModal: ''
    }
  },
  methods: {
    getBanner () {
      this.isLoading = true
      BannerSvc.GetEventBanner('Index_Banner')
        .then((SvcData) => {
          this.banner = SvcData
          this.isLoading = false
        })
      BannerSvc.GetEventBanner('Index_m_Banner')
        .then((SvcData) => {
          this.banner_m = SvcData
        })
    },
    getVideo () {
      BannerSvc.GetEventBanner('Papa_Video')
        .then((SvcData) => {
          this.video = checkVideoSrc(SvcData[0].targetUrl)
          // 影片自動播放
          // setTimeout(() => {
          //   document.querySelector('#vid').play()
          // }, 500)
        })
    },
    getActicityBanner () {
      BannerSvc.GetEventBanner('Promote_Event')
        .then((SvcData) => {
          this.activitybanner1 = SvcData.slice(0, 2)
        })
      BannerSvc.GetEventBanner('Season_Event')
        .then((SvcData) => {
          this.activitybanner2 = SvcData.slice(0, 2)
        })
      BannerSvc.GetEventBanner('New_Event')
        .then((SvcData) => {
          this.activitybanner3 = SvcData.slice(0, 2)
        })
    },
    getHotProduct () {
      ProductSvc.GetHotProduct()
        .then((SvcData) => {
          this.hotProduct = SvcData
        })
    },
    //* 取得蓋板廣告
    getPopupAdvertise () {
      const url = `${process.env.VUE_APP_API}/api/widgets/activitybanner?code=Index_Ad`
      this.$http.get(url)
        .then((res) => {
          if (res.data.rtnCode === 0) {
            res.data.info.forEach((item) => {
              if (item.code.includes('m')) {
                this.advertise_m = item
              } else {
                this.advertise = item
              }
            })
            if (this.advertise && this.advertise_m) {
              this.adModal.show()
            }
          }
        })
    },
    //* 關閉蓋板廣告
    closeAdModal () {
      this.adModal.hide()
      const today = moment().format('YYYY/MM/DD')
      localStorage.setItem('admodal', today)
    }
  },
  mounted () {
    this.getBanner()
    this.getVideo()
    this.getActicityBanner()
    this.getHotProduct()
    // ? 從 line 進入記錄 query 參數，並導到登入頁面
    if (Object.keys(this.$route.query).length !== 0 && this.$route.query.path) {
      Object.keys(this.$route.query).forEach((item) => {
        sessionStorage.setItem(`${item}`, this.$route.query[item])
      })
      const AccessToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      const RefreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)PaPaRefreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (AccessToken || RefreshToken) {
        getGiftEvent().then((data) => {
          if (!data.isSuccess) {
            this.$swal.fire({
              title: `${data.message}(${data.rtnCode})`,
              allowOutsideClick: false,
              confirmButtonColor: '#003894',
              confirmButtonText: '確認',
              width: 400,
              customClass: {
                title: 'text-class',
                confirmButton: 'confirm-btn-class'
              }
            })
          } else {
            this.$swal.fire({
              title: `${data.message}`,
              allowOutsideClick: false,
              confirmButtonColor: '#003894',
              confirmButtonText: '確認',
              width: 400,
              customClass: {
                title: 'text-class',
                confirmButton: 'confirm-btn-class'
              }
            })
          }
          this.$router.push(sessionStorage.getItem('path'))
          sessionStorage.removeItem('path')
        })
      } else {
        this.$swal.fire({
          title: '請先登入',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          backdrop: true,
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        this.$router.push('/registerlogin')
      }
    } else {
      // ?蓋板廣告判斷(同日只出現一次)
      this.adModal = new bootstrap.Modal(this.$refs.adModal, { backdrop: 'static' })
      const today = moment().format('YYYY/MM/DD')
      const date = localStorage.getItem('admodal')
      if (!date || moment(today, 'YYYY/MM/DD').isAfter(date)) {
        this.getPopupAdvertise()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.swiper-pagination-bullet){
  background-color: #cacbce;
  width: 10px;
  height: 10px;
  @media (min-width: 992px) {
    width: 12px;
    height: 12px;
  }
}
::v-deep(.swiper-pagination-bullet-active){
  background-color: #003894;
}
::v-deep(.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet){
  margin: 0 8px;
  @media (min-width: 992px) {
    margin: 0 12px;
  }
}
.big-banner{
  height: 34vw;
  @media (max-width:1500px) {
    height: 36vw;
  }
  @media (max-width:767px) {
    height: 100vw;
  }
  @media (max-width:500px) {
    height: 103vw;
  }
  img {
    height: 31vw;
    @media (max-width:767px) {
      height: 92vw;
    }
  }
}
.wrap{
  @media (max-width:430px) {
    display: block!important;
  }
}
.title-height{
  line-height: 1.5;
  overflow: hidden;
  @media (max-width: 992px) {
    height: 3.6rem;
  }
  @media (max-width: 767px) {
    height: 3.2rem;
  }
}
.adModal{
  .modal-content{
    width: auto;
    background-color: transparent;
    border: 0;
  }
  .closeEventIcon{
    width: 32px;
  }
}
</style>
